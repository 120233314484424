import React from 'react';

import PlayAutoComponent from '@/components/registry/screens/PlayAuto/PlayAuto';

const PlayAuto = () => {
    return (
        <PlayAutoComponent />
    );
};

export default PlayAuto;
