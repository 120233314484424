import React from 'react';

import icon1 from '../../assets/icons/icon-1.svg';
import icon2 from '../../assets/icons/icon-2.svg';
import icon3 from '../../assets/icons/icon-3.svg';
import Screen from '../../components/screen/screen';
import { IRegistryScreenData } from '../../types/ScreenData';

const data: IRegistryScreenData = {
    hero: {
        title: 'PlayAuto',
        text : 'Cистема, которая позволяет использовать сервисы в&nbsp;автомобиле. Вы&nbsp;можете слушать музыку, строить маршруты или болтать с&nbsp;голосовым помощником.'
    },
    sections: [
        {
            title: 'Основные возможности программы',
            icon : icon1,
            tags : [
                'Интеграция со смартфонами',
                'Навигация',
                'Музыкальные сервисы',
                'Голосовой ассистент',
                'Интерфейс HMI',
                'Управление вызовами'
            ]
        },
        {
            title  : 'Стоимость использования программы',
            icon   : icon2,
            content: 'рассчитывается индивидуально в&nbsp;зависимости от&nbsp;функционала, объёмов и&nbsp;других условий. Узнать подробности можно по&nbsp;адресу электронной почты <a href="mailto:partners@autotech.team">partners@autotech.team</a>'
        },
        {
            title  : 'Документация',
            icon   : icon3,
            content: `
      <ul>
        <li><a href="/registry/opisanie-po.pdf" target="_blank">Описание ПО</a></li>
        <li><a href="/registry/instrukciya-playauto.pdf" target="_blank">Руководство по установке и эксплуатации</a></li>
      </ul>
      `
        }
    ]
};

const PlayAutoComponent = () => {
    return (
        <Screen data={data} />
    );
};

export default PlayAutoComponent;
